var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      staticClass: "avue-dialog main-box",
      attrs: { title: "常见隐患", visible: _vm.sortSettingShow, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.sortSettingShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("CommonTreeNew", {
            ref: "commonTreeCore",
            attrs: {
              treeTitle: "隐患分类",
              "node-key": "id",
              isLeafIcons: "",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              treeData: _vm.corePersonnelTreeData,
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
              treeExpand: false,
            },
            on: { getNodeClick: _vm.corePersonnelTreeNodeClick },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c("head-layout", { attrs: { "head-title": "隐患描述" } }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.corePersonnelTableOption,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.TableLoading,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.saveData(row)
                              },
                            },
                          },
                          [_vm._v("引入")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }