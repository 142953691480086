var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "search_box" }, [
      _c("div", { staticClass: "label" }, [_vm._v("关键字搜索：")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input,
            expression: "input",
          },
        ],
        attrs: { placeholder: "请输入内容", id: "tipinput" },
        domProps: { value: _vm.input },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.input = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { attrs: { id: "container" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }