<template>
  <div style="width: 100%">
    <div class="search_box">
      <div class="label">关键字搜索：</div>
      <input
        v-model="input"
        placeholder="请输入内容"
        id="tipinput"
      ></input>
    </div>
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"; //引入AMapLoader
// window._AMapSecurityConfig = {
//   securityJsCode: "21aff0e604ebb8197961273a5c8fc2c8",//高德申请的key的秘钥
// };
export default {
  data() {
    return {
      input: "",
      map: null,
      auto: null,
      placeSearch: null,
      lnglat: [],
      markers: [],
      position: {},
      dataForm: {
        lng:'',
        lat:'',
        address:''
      }
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // 地图初始化
    initMap() {
      AMapLoader.reset();
      AMapLoader.load({
        key: "f0ebdb07b19a4100b883b6e207d1f7e7",//高德申请的key
        version: "2.0",
        plugins: ["AMap.AutoComplete", "AMap.PlaceSearch", "AMap.Geocoder"],
      }).then((AMap) => {
        const map = new AMap.Map("container", {
          viewMode: "3D",
          terrain: true,
          zoom: 11,
          center: [116.474892, 40.010959],
        });
        this.map = map;
        // 关键字查询
        this.searchMap();
        this.setMarker()
        this.map.on("click", this.clickMapHandler);
      });
    },
    // 关键字查询
    searchMap() {
      // 搜索框自动完成类
      this.auto = new AMap.AutoComplete({
        input: "tipinput", // 使用联想输入的input的id
      });
      //构造地点查询类
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
      });
      // 当选中某条搜索记录时触发
      this.auto.on("select", this.selectSite);
    },
    //选中查询出的记录
    selectSite(e) {
      if (e.poi.location) {
        this.lnglat = [e.poi.location.lng, e.poi.location.lat];
        this.dataForm.lng = e.poi.location.lng;
        this.dataForm.lat = e.poi.location.lat;
        this.placeSearch.setCity(e.poi.adcode);
        this.placeSearch.search(e.poi.name); //关键字查询
        let geocoder = new AMap.Geocoder({});
        let that = this;
        geocoder.getAddress(that.lnglat, function (status, result) {
          if (status === "complete" && result.regeocode) {
            that.dataForm.address = result.regeocode.formattedAddress;
            that.$emit("selectAddress", that.dataForm)
          }
        });
      } else {
        this.getAddress()
      }
    },
    getAddress() {
      let that = this;
      let geocoder = new AMap.Geocoder({});
      geocoder.getLocation(that.input, function(status, result) {
        if (status === 'complete' && result.geocodes.length) {
          let data = result.geocodes[0];
          that.lnglat = [data.location.lng, data.location.lat];
          that.dataForm.lng = data.location.lng;
          that.dataForm.lat = data.location.lat;
          that.placeSearch.setCity(data.adcode);
          that.placeSearch.search(data.addressComponent.city); //关键字查询
          geocoder.getAddress(that.lnglat, function (status, result) {
            if (status === "complete" && result.regeocode) {
              that.dataForm.address = result.regeocode.formattedAddress;
              that.$emit("selectAddress", that.dataForm)
            }
          });
        } else {
          that.$message.error('根据地址查询位置失败');
        }
      })
    },
    clickMapHandler(e) {
      this.dataForm.lng = e.lnglat.getLng();
      this.dataForm.lat = e.lnglat.getLat();
      this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
      let geocoder = new AMap.Geocoder({});
      let that = this;
      geocoder.getAddress(this.lnglat, function (status, result) {
        if (status === "complete" && result.regeocode) {
          that.dataForm.address = result.regeocode.formattedAddress;
          that.input = result.regeocode.formattedAddress;
          that.$emit("getLocation", that.dataForm)
        }
      });
    },
    //  添加标记
    setMarker() {
      this.removeMarker();
      let marker = new AMap.Marker({
        position: [116.474892, 40.010959],
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    },
    // 删除之前后的标记点
    removeMarker() {
      if (this.markers) {
        this.map.remove(this.markers);
      }
    },
  },
};
</script>

<style lang="scss">
.search_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}

.label {
  color: #000;
  width: 90px;
  flex-shrink: 0;
}

#container {
  width: 100%;
  height: calc(100vh - 350px);
}

.amap-sug-result {
  z-index: 10240 !important;
}

#tipinput {
  width: calc(100% - 90px);
  height: 30px;
}
</style>
